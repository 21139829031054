<template>
  <main class="main__container">
    <ul>
      <li>
        
      </li>
    </ul>
  </main>
</template>

<script>

export default {
  name: 'Search',
  data() {
    return {
      list: []
    }
  },
  components: {
  },
  created() {
  },
  watch: {
  },  
  methods: {

  }
}
</script>