import dayjs from 'dayjs'
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)
dayjs.locale('ja');

export const Mixin =  {
  filters: {
    dayformat: function (date) {
      return dayjs(date).fromNow()
    }
  }
}