<template>
  <dir class="feed__container" :id="tabId">
    <div :class="{feed__container__inner: true, slide: slide}">
      <div class="feed">
        <header class="feed__header">
          <div class="flx--ctr--btw title__box">
            <p class="favicon">
              <img v-lazy="{'src': feedDetail.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}" alt="favicon" width="24px" height="24px">
            </p>
            <h2 :id="'head-' + tabId"><a :href="feedDetail.url" target="_blank" rel="noopener">{{feedDetail.title}}</a></h2>
          </div>
          <div class="flx--ctr--btw">
            <button @click="deleteCollection" aria-label="delete feed">
              <span class="icon__btn"><font-awesome-icon icon="minus" /></span>
            </button>
            <button @click="syncFeed" aria-label="sync feed">
              <span class="icon__btn"><font-awesome-icon icon="sync" /></span>
            </button>
          </div>
        </header>
        <div v-if="loading">
          <Loader />
        </div>
        <div v-else
         class="feed_body" :id="'feed_body-' + tabId">
          <Article v-for="(data, i) in feed" :key="col_id + '-' + i" :func="getComments" :article="data" :publisher="publisher" />
          <button class="readmore" @click="addFeed()" v-if="!completed" aria-label="read more">続きを読む</button>
        </div>
        <button
          class="scrolltop__btn"
          aria-label="scroll to top"
          v-scroll-to="{
          el: '#head-' + tabId,
          container: '#feed_body-' + tabId,
          duration: 500,
          easing: 'linear',
          offset: 0,
          force: true,
          cancelable: true,
          x: false,
          y: true
          }"><span class="icon__btn"><font-awesome-icon icon="arrow-up" /></span>
          </button>
      </div>
      <div class="feed comments">
        <header class="feed__header">
          <button @click="toggleSlide(false)" aria-label="back to feed"> ＜ 戻る</button>
        </header>
        <CommentFeed :comments="comments" :commentLoading="commentLoading" />
      </div>
    </div>
  </dir>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Article from '@/components/Molecules/Article.vue'
import CommentFeed from '@/components/Organisms/CommentFeed.vue'
import Loader from '@/components/Atoms/Loader.vue'
import Parser from 'rss-parser'

export default {
  name: 'MainFeed',
  props: ['tabId', 'feedDetail', 'col_id'],
  data() {
    return {
      slide: false,
      feed: [],
      feedData: [],
      publisher: {},
      loading: false,
      comments: [],
      commentLoading: false,
      page: 0,
      pageTotal: 0,
      perpage: 10,
      completed: false
    }
  },
  created(){
    this.syncFeed()
  },
  mounted() {
    // this.getFeed()
  },
  watch: {
    '$route': ['dataInit'],
    'feedChild': ['syncFeed'],
  },
  components: {
    Article,
    Loader,
    CommentFeed
  },
  computed: {
    ...mapGetters([
      "getMyfeed"
    ]),
  },
  methods: {
    ...mapActions([
      'collectionRemove'
    ]),
    dataInit(){
      this.loading = false
      this.feed = []
      this.feedData = []
      this.page = 0
      this.pageTotal = 0
      this.completed = false
    },
    toggleSlide(val){
      this.slide = val
    },
    syncFeed: async function(){
      this.dataInit()
      await this.getFeed()
    },
    getComments: async function(url){
      // console.log('getComments');
      this.commentLoading = true
      this.slide = true

      const apiurl = 'https://api.feedclip.net/'
      const data = `
        {
          comments(url: "` + url + `"){
            user, timestamp, comment, platform
          }
        }
      `
      await fetch(apiurl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({query: data})
      }).then(response => response.json())
      .then(result => {
        this.comments = result.data.comments
      })

      this.commentLoading = false
    },
    getFeed: async function(){
      this.loading = true
      let parser = new Parser({
        headers: {},
      });
      const feedData = await parser.parseURL('https://cors-proxy.circletokyo.workers.dev/corsproxy/?apiurl=' + this.feedDetail.rss);
      this.feedData = feedData.items

      // urlが相対パスの時の対処 paging
      for(let i in this.feedData){
        if(this.feedData[i].link.match(/(https?:\/\/[\x21-\x7e]+)/gi) === null){
          const parser = new URL(feedData.link);
          this.feedData[i].link = parser.protocol + '//' + parser.host + this.feedData[i].link
        }
      }
      this.pageTotal = Math.ceil(this.feedData.length / this.perpage)
      this.addFeed()

      this.publisher.link = feedData.link
      this.publisher.title = feedData.title

      this.loading = false
    },
    addFeed(){
      if(this.page <= this.pageTotal){
        // console.log('add feed');
        this.page += 1
        if(this.page === this.pageTotal){
          this.completed = true
        }
        const end = this.page * this.perpage
        const offset = (this.page - 1) * this.perpage

        const data = this.feedData.slice(offset, end)

        for(let i in data){
          this.feed.push(data[i])
        }
      }
    },
    deleteCollection: async function(){
      await this.collectionRemove({id: this.col_id})
    }
  }
}
</script>
