var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submenu__box"},[_c('nav',{staticClass:"submenus"},[_c('ul',[_vm._l((_vm.menus),function(menu,i){return _c('li',{key:'menu-' + i},[_c('router-link',{class:{btn: true, current: (_vm.currentPath === menu.link)},attrs:{"to":menu.link}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":menu.icon}})],1)])],1)}),_c('li',[_c('button',{staticClass:"btn",attrs:{"aria-label":"update app"},on:{"click":_vm.doUpdate}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"sync"}})],1),_c('span',{staticClass:"txt"},[_vm._v("Update")])])])],2)]),_c('nav',{staticClass:"submenus cols"},[_c('ul',[_vm._l((_vm.collections),function(tab,ii){return _c('li',{key:'tab-' + ii},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#scroll' + (ii + 1),
          container: '#mainframe',
          duration: 300,
          easing: 'linear',
          offset: 0,
          force: true,
          cancelable: false,
          x: true,
          y: false
        }),expression:"{\n          el: '#scroll' + (ii + 1),\n          container: '#mainframe',\n          duration: 300,\n          easing: 'linear',\n          offset: 0,\n          force: true,\n          cancelable: false,\n          x: true,\n          y: false\n        }"}],staticClass:"btn",attrs:{"href":'#scroll' + (ii + 1),"aria-label":"move to this tab"}},[_c('span',{staticClass:"favicon"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({'src': tab.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}),expression:"{'src': tab.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}"}],attrs:{"width":"16px","height":"16px","alt":"favicon"}})])])])}),_c('li',[_c('router-link',{staticClass:"btn",attrs:{"to":'/add/'}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":'plus-square'}})],1)])],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }