<template functional>
  <footer class="footer__box">
    <p class="cp">
    (c) {{props.year}} feedclip.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['year'],
}
</script>