<template>
  <div id="app">
    <Head />
    <div class="main__frame" id="mainframe">
      <router-view/>
    </div>
    <Footer :year="new Date().getFullYear()"/>
  </div>
</template>

<script>
import Head from '@/components/Organisms/Head.vue'
import Footer from '@/components/Organisms/Footer.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    Head,
    Footer,
  },
  computed: {
    ...mapGetters([
      "getFeedCount", "getFeed"
    ])
  },
  created() {
    this.syncFeedMaster()
  },
  methods: {
    ...mapActions([
      'feedAdd'
    ]),
    syncFeedMaster: async function(){
      const apiurl = 'https://api.feedclip.net/feed/list'
      const Feeds = await fetch(apiurl).then(response => {return response.json()})
      for(let i in Feeds){
        await this.feedAdd(Feeds[i])
      }
    }
  }
}
</script>
