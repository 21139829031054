<template>
  <div class="feed_body">
    <p v-if="commentLoading">
      <Loader />
    </p>
    <div v-else>
      <ul v-if="comments.length > 0" class="comments">
        <li v-for="(comment, i) in comments" :key="i" class="comment">
          <p class="text" v-html="urlize(comment.comment)"></p>
          <div class="comment__bottom">
            <p class="user">
              <span class="favicon" v-if="comment.platform === 'htn'"><img src="https://www.google.com/s2/favicons?domain=b.hatena.com" width="12px" height="12px"/></span>
              <span class="favicon" v-if="comment.platform === 'twr'"><img src="https://www.google.com/s2/favicons?domain=twitter.com" width="12px" height="12px"/></span>
              <a :href="comment | geneUserUrl" target="_blank" rel="noopener nofollow"><span class="name">{{comment.user}}</span></a>
            </p>
            <p class="date">{{comment.timestamp | dayformat}}</p>
          </div>
        </li>
      </ul>
      <p v-else>コメントはありませんでした。</p>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Atoms/Loader.vue'
import textTolink from 'text-to-link'

export default {
  name: 'CommentFeed',
  data() {
    return {
    }
  },
  props: ['comments', 'commentLoading'],
  components: {
    Loader,
  },
  created() {
  },
  filters:{
    geneUserUrl(data){
      let url = ''
      switch (data.platform) {
        case 'twr':
          url += 'https://twitter.com/' + data.user
          break;
        case 'htn':
          url += 'https://b.hatena.ne.jp/' + data.user
          break;
        default:
          break;
      }
      return url
    }
  },
  methods: {
    urlize: function(text){
      return textTolink(text, { target: "_blank", rel: "nofollow noopener"})
    },
  }
}
</script>