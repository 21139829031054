<template>
  <div class="submenu__box">
    <nav class="submenus">
      <ul>
        <li v-for="(menu, i) in menus" :key="'menu-' + i">
          <router-link :to="menu.link" :class="{btn: true, current: (currentPath === menu.link)}">
          <span class="icon"><font-awesome-icon :icon="menu.icon" /></span>
          </router-link>
        </li>
        <li>
          <button class="btn" @click="doUpdate" aria-label="update app">
          <span class="icon"><font-awesome-icon icon="sync" /></span>
          <span class="txt">Update</span>
          </button>
        </li>
      </ul>
    </nav>
    <nav class="submenus cols">
      <ul>
        <li v-for="(tab, ii) in collections" :key="'tab-' + ii">
          <a :href="'#scroll' + (ii + 1)"
            class="btn"
            aria-label="move to this tab"
            v-scroll-to="{
            el: '#scroll' + (ii + 1),
            container: '#mainframe',
            duration: 300,
            easing: 'linear',
            offset: 0,
            force: true,
            cancelable: false,
            x: true,
            y: false
          }"
          >
          <span class="favicon"><img width="16px" height="16px"  v-lazy="{'src': tab.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}" alt="favicon" /></span>
          </a>
        </li>
        <li>
          <router-link :to="'/add/'" class="btn">
          <span class="icon"><font-awesome-icon :icon="'plus-square'" /></span>
          </router-link>
        </li>

      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {orderBy} from 'vue-idb'

export default {
  name: 'GMenus',
  data() {
    return {
      menus: [
        {link: '/', label: 'Home', icon: 'home'},
        {link: '/clipped', label: 'Clipped', icon: 'clipboard-check'},
      ],
      comments: [],
      commentLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      "getCollection"
    ]),
    currentPath: function (){
      return this.$route.path;
    },
    collections: function(){
      return orderBy(this.getCollection, 'updated_at', false)
    }
  },
  components: {
  },
  methods: {
    doUpdate: async function(){
      window.navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
      window.location.reload(true);
    }
  },
}
</script>
