<template>
  <main class="main__container">
    <MainFeed
    v-for="(col,i) in collections"
    :key="col.id"
    :tabId="'scroll' + (i + 1)"
    :feedDetail="col"
    :col_id="col.id"
 />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import MainFeed from '@/components/Organisms/MainFeed.vue'
import {orderBy} from 'vue-idb'

export default {
  name: 'home',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      "getCollection"
    ]),
    collections: function(){
      return orderBy(this.getCollection, 'updated_at', false)
    }
  },
  components: {
    MainFeed,
  },
  created() {
  },
  watch: {
    // '$route': ['dataInit']
  },
  methods: {
  }
}
</script>