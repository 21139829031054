import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import css from './assets/css/main.css'
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLazyload from 'vue-lazyload'
import { faCommentDots, faAlignLeft, faBars, faPlusSquare, faHome, faClipboardCheck, faComments, faShareAlt, faArrowUp, faSync, faEye, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons'
import VueScrollTo from 'vue-scrollto';
import {Mixin} from '@/mixins' 


Vue.use(VueScrollTo)
Vue.use(VueLazyload, {
  preLoad: 1.5,
  // error: 'dist/error.png',
  loading: '/imgs/ogp.png',
  attempt: 3,
  lazyComponent: true
})

library.add( faCommentDots, faAlignLeft, faBars, faPlusSquare, faHome, faClipboardCheck, faComments, faShareAlt, faArrowUp, faSync, faEye, faTimes, faMinus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.mixin(Mixin)

new Vue({
  router,
  store,
  css,
  render: h => h(App)
}).$mount('#app')
