import Vue from 'vue'
import Vuex from 'vuex'
import VueIdb from './idb'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hydrated: false,
  },
  mutations: {
    'DELETE_INDEXED_DB'(){}
  },
  actions: {
  },
  modules: VueIdb.modules,
  plugins: [ VueIdb.plugin ],
  getters: {
    hydrated: state => state.hydrated
  },
  strict: true  
})
