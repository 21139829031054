import Vue from 'vue'
import VueIdb from 'vue-idb'

Vue.use(VueIdb)

export default new VueIdb({
  database: 'feedclip',
  schemas: [
    { feed: 'id, title, url, icon, rss, updated_at' },
    { collection: 'id, feeds, title, url, icon, rss, updated_at' },
  ],
  options: {
    feed: { type: 'biglist', primary: 'id', label: 'title', updated_at: 'updated_at' },
    collection: { type: 'list', primary: 'id', label: 'title', updated_at: 'updated_at' },
  },
})