<template>
  <div class="imgbox" v-bind:class="poptoggle">
    <p class="image" @click="popupImg()">
      <img v-lazy="image" width="100%" :alt="title" />
    </p>
    <button class="close__btn" aria-label="close image popup" @click="popupImg(false)"><font-awesome-icon icon="times" /></button>
  </div>
</template>

<script>
export default {
  name: 'Thumbnail',
  data() {
    return {
      image: '',
      loaded: false,
      popup: false
    }
  },
  computed: {
    poptoggle: {
      get: function () {
        return {
          popup: this.popup
        }
      },
      set: function (val) {
        this.popup = val
      }
    }
  },  
  created(){
    this.getOGP()
  },
  props: ['url', 'title'],
  methods: {
    getOGP: async function(){
      const url = 'https://api.feedclip.net/ogp/?url=' + encodeURI(this.url)
      this.image = await fetch(url).then(response => response.json())
      .then(result => {
        return result.image.replace('http:', 'https:')
      })
    },
    popupImg: function(val){
      if(val){
        this.poptoggle = val
      }else{
        this.poptoggle = this.popup? false: true;
      }
    },
  }
}
</script>