<template>
  <article class="feed__article">
    <div class="article__head">
      <p class="site">
        <a :href="publisher.link" target="_blank" rel="noopener" class="site__inner">
          <span class="icon"><img :src="'https://www.google.com/s2/favicons?domain=' + publisher.link" width="16px" height="16px" :alt="publisher.title + '\'s favicon'" /></span>
          <span class="name">{{publisher.title}}</span>
        </a>
      </p>
      <p class="updated">
        {{article.isoDate | dayformat}}
      </p>
    </div>
    <h3 class="title"><a :href="article.link" target="_blank" rel="noopener">{{article.title}}</a></h3>
    <p class="url">
      <a :href="article.link" target="_blank" rel="noopener">{{article.link}}</a>
    </p>
    <Thumbnail :url="article.link" :title="article.title" />
    <nav class="article__menus">
      <ul class="article__menus__list">
        <li>
          <button @click="func(article.link)" class="btn" aria-label="view comments about this article">
            <span class="icon"><font-awesome-icon icon="comments" /></span>
          </button>
        </li>
      </ul>
      <ul class="article__menus__list">
        <li class="sp__only">
          <button class="btn" @click="doShareAPI(article.link)" aria-label="share this article">
            <span class="icon"><font-awesome-icon icon="share-alt" /></span>
          </button>
        </li>
        <li>
          <button class="btn" aria-label="add this article to checklist">
            <span class="icon"><font-awesome-icon icon="clipboard-check" /></span>
          </button>
        </li>
      </ul>
    </nav>
  </article>
</template>

<script>
import Thumbnail from '@/components/Atoms/Thumbnail.vue'

export default {
  name: 'Article',
  data() {
    return {
      image: '',
    }
  },
  props: ['func', 'article', 'publisher'],
  components: {
    Thumbnail
  },
  computed: {
    ogpImage: {
      get: function () {
        return this.image
      },
      set: function (val) {
        this.image = val
      }
    }
  },
  created() {
  },
  mounted(){
  },
  methods: {
    doShareAPI (url){
      if(navigator.share){
        navigator.share({url:url})
      }
    }
  }
}
</script>