var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dir',{staticClass:"feed__container",attrs:{"id":_vm.tabId}},[_c('div',{class:{feed__container__inner: true, slide: _vm.slide}},[_c('div',{staticClass:"feed"},[_c('header',{staticClass:"feed__header"},[_c('div',{staticClass:"flx--ctr--btw title__box"},[_c('p',{staticClass:"favicon"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({'src': _vm.feedDetail.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}),expression:"{'src': feedDetail.icon, 'error': '/imgs/logo-gray.png', 'loading': '/imgs/logo-gray.png'}"}],attrs:{"alt":"favicon","width":"24px","height":"24px"}})]),_c('h2',{attrs:{"id":'head-' + _vm.tabId}},[_c('a',{attrs:{"href":_vm.feedDetail.url,"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(_vm.feedDetail.title))])])]),_c('div',{staticClass:"flx--ctr--btw"},[_c('button',{attrs:{"aria-label":"delete feed"},on:{"click":_vm.deleteCollection}},[_c('span',{staticClass:"icon__btn"},[_c('font-awesome-icon',{attrs:{"icon":"minus"}})],1)]),_c('button',{attrs:{"aria-label":"sync feed"},on:{"click":_vm.syncFeed}},[_c('span',{staticClass:"icon__btn"},[_c('font-awesome-icon',{attrs:{"icon":"sync"}})],1)])])]),(_vm.loading)?_c('div',[_c('Loader')],1):_c('div',{staticClass:"feed_body",attrs:{"id":'feed_body-' + _vm.tabId}},[_vm._l((_vm.feed),function(data,i){return _c('Article',{key:_vm.col_id + '-' + i,attrs:{"func":_vm.getComments,"article":data,"publisher":_vm.publisher}})}),(!_vm.completed)?_c('button',{staticClass:"readmore",attrs:{"aria-label":"read more"},on:{"click":function($event){return _vm.addFeed()}}},[_vm._v("続きを読む")]):_vm._e()],2),_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#head-' + _vm.tabId,
        container: '#feed_body-' + _vm.tabId,
        duration: 500,
        easing: 'linear',
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true
        }),expression:"{\n        el: '#head-' + tabId,\n        container: '#feed_body-' + tabId,\n        duration: 500,\n        easing: 'linear',\n        offset: 0,\n        force: true,\n        cancelable: true,\n        x: false,\n        y: true\n        }"}],staticClass:"scrolltop__btn",attrs:{"aria-label":"scroll to top"}},[_c('span',{staticClass:"icon__btn"},[_c('font-awesome-icon',{attrs:{"icon":"arrow-up"}})],1)])]),_c('div',{staticClass:"feed comments"},[_c('header',{staticClass:"feed__header"},[_c('button',{attrs:{"aria-label":"back to feed"},on:{"click":function($event){return _vm.toggleSlide(false)}}},[_vm._v(" ＜ 戻る")])]),_c('CommentFeed',{attrs:{"comments":_vm.comments,"commentLoading":_vm.commentLoading}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }