<template>
  <header class="ghead">
    <GMenus />
  </header>
</template>

<script>
import GMenus from '@/components/Organisms/GMenus.vue'

export default {
  name: 'Header',
  components: {
    GMenus
  },
  computed: {
  }
}
</script>